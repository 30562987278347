<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="primary subtitle-1 white--text elevation-2 pa-3"
          >Faça seu pedido de reembolso pelo app da sua operadora</v-card
        >
      </v-col>

      <v-col cols="12">
        <v-list
          :two-line="true"
          :rounded="true"
          :avatar="true"
          color="transparent"
        >
          <template v-for="(item, index) in itemsSorted()">
            <a
              :href="$util.getUrlApp(item)"
              :key="'item' + index"
              :style="{ 'text-decoration': 'none' }"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-img :src="'/img/logos/' + item.img"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="white--text">{{ item.descricao }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </a>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          descricao: "Caixa",
          img: "logoCaixaSaude.jpg",
          weblink: "https://saude.caixa.gov.br/",
          ios: {
            id: "783187739"
          },
          android: {
            package: "br.gov.caixa.saudecaixamobile"
          }
        },
        {
          descricao: "Sulamerica",
          img: "logoSulamericaSaude.jpg",
          weblink: "https://portal.sulamericaseguros.com.br/",
          ios: {
            id: "492556516"
          },
          android: {
            // scheme: "tagmanager.c.br.com.sulamerica.sam.saude",
            package: "br.com.sulamerica.sam.saude"
          }
        },
        {
          descricao: "Bradesco",
          img: "logoBradescoSaude.jpg",
          weblink: "https://www.bradescoseguros.com.br/",
          ios: {
            id: "432088616"
          },
          android: {
            host: "login",
            scheme: "bsaude",
            package: "br.com.bradseg.segurobradescosaude"
          }
        },
        {
          descricao: "Seguros Unimed",
          img: "logoSegurosUnimed.jpg",
          weblink: "https://www.segurosunimed.com.br/",
          ios: {
            id: "1448366502"
          },
          android: {
            // scheme: "@7F1201F0",
            // package: "br.com.segurosunimed.reembolsodigital",
            link: "https://segurosunimedapp.page.link/prd"
          }
        },
        {
          descricao: "Amil",
          img: "logoAmilSaude.jpg",
          weblink: "https://www.amil.com.br/",
          ios: {
            id: "471890526"
          },
          android: {
            scheme: "@7F1000F7",
            package: "br.com.amil.beneficiarios"
          }
        },
        {
          descricao: "Grupo NotreDame Intermédica (GNDI)",
          img: "logoGNDI.jpg",
          weblink: "https://www.gndi.com.br/",
          ios: {
            id: "1430190022"
          },
          android: {
            package: "br.com.gndi.beneficiario.gndieasy"
          }
        }
      ]
    };
  },
  methods: {
    itemsSorted() {
      return this.items.slice().sort((a, b) => {
        return a.descricao.localeCompare(b.descricao);
      });
    }
  }
};
</script>

<style></style>
